import React from "react"
import { Link } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Image from "../components/image"

const useStyles = makeStyles((theme) => ({
  phrase: {
    color: "gray",
    textAlign: 'center',
    paddingTop: 150,
    marginLeft: 30,
    marginBottom: 50,
    marginRight: 30,
  },
}));

const ThanksPage = (props) => {

  const classes = useStyles();
  const breakpoints = useBreakpoint();
  return (
  <Layout location={props.location} breakpoints={breakpoints}>
    <Seo title="お問合せ完了" />
    <div  style={{position: "fixed", top: 0, minWidth: "100%", height: "40vh", zIndex: -1,backgroundColor: "gainsboro"}}><Image filename="carrot_eyecatch.jpeg" style={{height: "inherit"}}/></div>
    <div className={classes.phrase} style = {{color: "white",fontSize: breakpoints?.sm ? 28 : breakpoints.md ? 36 : 42}}>
      お問合せありがとうございました。
    </div>
    <div style={{backgroundColor: "white", paddingTop: 50,paddingBottom: 50,textAlign: "center"}}>
      <p>数日お待ちいただいても連絡がない場合は、当社からのご返信メールが正常に届いていない可能性がございます。</p>
      <p>大変お手数ですが、お電話でお知らせいただけますようお願いいたします。</p>
    </div>
  </Layout>
  )
}

export default ThanksPage
